import React from 'react';
import styled from "styled-components";
import Logo from "../../styles/GC-Logo.png";
import {Link} from "react-router-dom";
import tiktok from "../../styles/tiktok-share-icon-black-1.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const StyledSidebar = styled.div`
    height: 100%;
    width: 100%;
    z-index: 999;
    text-align: right;
    position: relative;
    
    a {
      text-decoration: none;
      color: lightgrey;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    
    .logo-container {
        opacity: 0.5;
        img {
          max-width: 140px;
        }
    }
    
    .menu-container {
      display: grid;
      cursor: pointer;
      margin-top: 2em;
      padding-bottom: 2em;
      border-bottom: 1px solid white;
    }
    
    .social-container {
      display: flex;
      margin-top: 2em;
      align-items: center;
      justify-content: flex-end;
      opacity: 0.5;
      
      .singleItem {
          margin-left: 8px;
          &:hover {
            transform: scale(0.85);
            transition: all 100ms ease-in-out;
          }
      }
    
      img {
        max-width: 16px !important;
        filter: invert(0.9);
        &:hover {
          transform: scale(0.85);
          transition: all 100ms ease-in-out;
        }
      }
    }
    
    h1 {
      color: white;
      transition: color 50ms ease-in;
      &:hover {
        color: black;
        transition: color 50ms ease-in;
      }
    }
`;


function Sidebar() {

    return (
        <StyledSidebar>
            <Link to={'/'} className={'logo-container'}>
                <img alt={'logo'} src={Logo}/>
            </Link>
            <div className={'menu-container'}>
                <Link to={"/"}><h1>Home</h1></Link>
                <Link to={"/gutscheine"}><h1>Gutscheine</h1></Link>
                <Link to={"/kontakt"}><h1>Kontakt</h1></Link>
            </div>
            <div className={'social-container'}>
                <a href={'https://www.tiktok.com/glashauscenter'} target={'_blank'} rel="noopener noreferrer"><img alt="tiktok" src={ tiktok }/></a>
                <a href={'https://www.facebook.com/glashaus.center'} target={'_blank'} rel="noopener noreferrer"><FontAwesomeIcon className={'singleItem'} icon={['fab', 'facebook']} /></a>
                <a href={'https://www.instagram.com/glashaus_worbis'} target={'_blank'} rel="noopener noreferrer"><FontAwesomeIcon className={'singleItem'} icon={['fab', 'instagram']} /></a>
                <a href={'mailto:info@glashaus-center.de'}><FontAwesomeIcon className={'singleItem'} icon={'envelope'}/></a>
            </div>
        </StyledSidebar>
    )
}

export default Sidebar;