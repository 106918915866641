import React from 'react';
import { StyledCopyrightBar } from './styledCopyrightBar';
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter, Link } from 'react-router-dom';
import tiktok from "../../styles/tiktok-share-icon-black-1.svg";

function CopyRightBar(props) {
    const currentYear = moment().format('YYYY');
    const location = props.history.location.pathname;

    const isSelected = (path) => {
        return location === path;
    };

    return (
        <StyledCopyrightBar>
            <div className={'mobileMenu'}>
                <Link to={"/"} className={ isSelected('/') ?'mobileMenu__item mobileMenu__item__selected' : 'mobileMenu__item'}><h5>Home</h5></Link>
                <Link to={"/gutscheine"} className={isSelected('/gutscheine') ?'mobileMenu__item mobileMenu__item__selected' : 'mobileMenu__item'}><h5>Gutscheine</h5></Link>
            </div>
            <div className={'container'}>
                <h5>© { currentYear } | Glashaus Worbis</h5>
                <div className={'container__socials'}>
                    <a href={'https://www.tiktok.com/glashauscenter'} target={'_blank'} className={'singleItem'} rel="noopener noreferrer"><img alt="tiktok" src={ tiktok }/></a>
                    <a href={'https://www.facebook.com/glashaus.center'} target={'_blank'} rel="noopener noreferrer"><FontAwesomeIcon className={'singleItem'} icon={['fab', 'facebook']} /></a>
                    <a href={'https://www.facebook.com/glashaus_worbis'} target={'_blank'} rel="noopener noreferrer"><FontAwesomeIcon className={'singleItem'} icon={['fab', 'instagram']} /></a>
                    <a href={'mailto:info@glashaus-center.de'}><FontAwesomeIcon className={'singleItem'} icon={'envelope'}/></a>
                </div>
            </div>
        </StyledCopyrightBar>
    )
}

export default withRouter(CopyRightBar);