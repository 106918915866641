import React from 'react';
import styled from "styled-components";
import contactBG from '../../styles/contactBG.jpg'
import bgPattern from '../../styles/background_pattern_01.png';

const StyledVoucher = styled.div`
    background: black;
    height: 100%;
    width: 100%;
    background-size: 50px 50px, contain;
    background-image: url(${ bgPattern });
    background-repeat: repeat;
    min-height: 100vh;
`;
const StyledPromo = styled.div`
    padding: 1em;
    text-align: center;
    background-color: orangered;
    color: white;
    
    @media(max-width: 501px) {
      font-size: 12px;
    }
`;
const StyledVoucherContent = styled.div`
    display: grid;
    max-width: 1500px;
    grid-column-gap: 2em;
    grid-template-columns: 2fr 3fr;
    
    @media(max-width: 701px) {
      padding: 1em;
      grid-template-columns: 1fr;
    }
`;
const StyledPreview = styled.div`
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-image: url(${contactBG});
    
    @media(max-width: 701px) {
      display: none;
    }
`;
const StyledSelection = styled.div`
      padding: 2em 2em 2em 2em;
      color: white;
      
      h5 {
        color: lightgrey;
        font-weight: 400;
      }
            
      .title {
        margin-bottom: 2em;
        text-align: left;
      }
           
      @media(max-width: 501px) {
          padding: 1em 1em 6em 1em;
      }
`;

function Contact() {

    return (
        <StyledVoucher>
            <StyledPromo>
                <h5>Achtung: Aufgrund der anhaltenden Coronakrise dauerhaft geschlossen!</h5>
            </StyledPromo>
            <StyledVoucherContent>
                <StyledPreview></StyledPreview>
                <StyledSelection>
                    <div className={'title'}>
                        <h3>Adresse</h3>
                        <h5>Glashaus Center Worbis</h5>
                        <h5>Duderstädter Allee 45</h5>
                        <h5>37339 Leinefelde-Worbis</h5>
                    </div>
                    <div className={'title'}>
                        <h3>Impressum</h3>
                        <h5>Vertreten durch Licht & Ton</h5>
                        <h5>info@glashaus-center.de</h5>
                    </div>
                    <div className={'title'}>
                        <h3>Ansprechpartner</h3>
                        <h5>Steffen Thunert & Marcus Gassmann</h5>
                    </div>
                    <div className={'title'}>
                        <h3>Socials</h3>
                        <h5>https://www.facebook.com/glashaus.worbis</h5>
                        <h5>https://www.instagram.com/glashaus_worbis</h5>
                        <h5>https://vm.tiktok.com/tkG4mt/</h5>
                    </div>
                </StyledSelection>
            </StyledVoucherContent>
        </StyledVoucher>
    )
}

export default Contact;