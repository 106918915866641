import styled from 'styled-components';

const StyledHeader = styled.div`
  background: black;
  color: lightgrey;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
    
  .container {
    max-width: 1500px;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    grid-template-columns: 160px auto 1fr auto;
    grid-column-gap: 2em;
    align-items: center;
    padding: 1em 2em;
    
    &__name {
      display: grid;
      grid-template-columns: auto;
      align-items: center;
      
      &__logo {
        img {
          max-height: 50px;
          max-width: 160px;
          margin-right: 10px;
        }
        @media(max-width: 500px) {
          img {
            max-width: 80px;
          }
        }
      }
    }
    
    &__menu {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-column-gap: 1em;
        
        &__item {
            text-decoration: none;
            color: grey;

            &:hover {
              transform: scale(0.9);
              transition: all 100ms ease-in-out;
            }
            
            &__selected {
              color: orangered;
            }
        }
    }
  }
  
  @media(max-width: 500px) {
      .container {
          grid-template-columns: 100px 1fr auto !important;
          padding: 0.5em 1em;
          img {
            max-width: 100px;
          }
          
          &__menu {
            display: none !important;
          }
      }
  }
`;

const StyledIconContainer = styled.div`
      display: grid;
      align-items: center;
      grid-column-gap: 0.5em;
      cursor: pointer;
      transition: all 100ms ease-in-out;
      color: lightgrey;
      justify-self: right;
      grid-template-columns: repeat(4, 1fr);
      
      a {
        display: flex;
        align-items: center;
        color: lightgrey;
      }
      
      .singleItem {
          &:hover {
            transform: scale(0.85);
            transition: all 100ms ease-in-out;
          }
      }
`;

const StyledSocialsContainer = styled(StyledIconContainer)`
    grid-template-columns: repeat(4, 1fr);
    
    img {
      max-width: 16px !important;
      filter: invert(0.9);
      &:hover {
        transform: scale(0.85);
        transition: all 100ms ease-in-out;
      }
    }
`;

const StyledBurgerMenu = styled.div`
    color: orangered;
    cursor: pointer;
    &:hover {
        transform: scale(0.85);
        transition: all 100ms ease-in-out;
      }
`;
export { StyledHeader, StyledSocialsContainer, StyledBurgerMenu };