import React, { useState } from 'react';
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { PayPalButton } from "react-paypal-button-v2";
import { getPaypalClientId } from '../../config.js';
import _ from 'lodash';
import { createVoucherPayment } from '../../resources/resource_service';
import orange from '../../styles/stoffband-orange.jpg';
import blue from '../../styles/stoffband-blue.jpg';
import drinks from '../../styles/drinks.png';
import drinks2 from '../../styles/drinks-2.png';

const StyledVoucher = styled.div`
    height: 100%;
    width: 100%;
    background-color: white;
    min-height: 100vh;
`;
const StyledPromo = styled.div`
    padding: 1em;
    text-align: center;
    background-color: orangered;
    color: white;
    
    @media(max-width: 701px) {
      font-size: 12px;
    }
`;
const StyledVoucherContent = styled.div`
    padding: 2em;
    display: grid;
    max-width: 1500px;
    grid-column-gap: 4em;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: 2fr 3fr;
    
    @media(max-width: 701px) {
      padding: 1em;
      grid-template-columns: 1fr;
    }
`;
const StyledPreview = styled.div`
    width: 100%;
    z-index: 0 !important;
    
    .value {
        text-align: left;
        overflow: hidden;
        
        &__options {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          grid-gap: 0.5em;
          margin-top: 0.5em;
          max-width: 100% !important;
          z-index: 0 !important;
          
          .singleOption {
            background-color: #F0F0F0;
            font-weight: 600;
            padding: 2em;
            border-radius: 4px;
            text-align: center;
            color: #1A1A1A;
            transition: all 100ms ease-in-out;
            cursor: pointer;
            font-size: 18px;
         
            &:hover {
              transform: scale(0.98);
              transition: all 100ms ease-in-out;
            }
          }
          
          .selected {
              background-color: orangered;
              color: white;
          }
        }
      }
      
      @media(max-width: 701px) {
          overflow: hidden;
    
          .value__options {
            display: grid;
            grid-template-columns: 1fr 1fr;
            
            .singleOption {
            font-size: 14px;
            padding: 1em;
              &:hover {
                transform: scale(1);
              }
            }
          }
      }

`;
const StyledSelection = styled.div`
      
      text-align: left;
      display: grid;
      grid-row-gap: 0.5em;
      max-width: 600px;
      margin-bottom: 6em;
      
      .paypal-buttons {
        z-index: 1 !important;
      }
      
      .title {
        margin: 0.5em 0;
        text-align: left
      }
            
      .count {
        margin: 2em 0;
        text-align: left;
        
        &__counter {
          grid-template-columns: 1fr 1fr auto;
          display: grid;
          grid-column-gap: 1em;
          background-color: #F0F0F0;
          border-radius: 4px;
          border: 1px solid lightgrey;
          color: orangered;
          font-weight: 700;
          margin-top: 0.5em;
          align-items: center;
          
          .icon {
            cursor: pointer;
            padding: 1em;
          }
        }
      }
      
      .items {
        padding-bottom: 1em;
      }
      
      .delivery {
        display: grid;
        grid-template-columns: 1fr auto;
        color: grey;
        align-items: center;
        padding: 1em 0;
        border-bottom: 1px dashed lightgrey;
        text-align: right;
         
        h5 {
          font-size: 18px;
        }
        
        .highlight {
          color: orangered;
          font-weight: 400;
        }
        
        img {
          max-width: 250px;
        }
        
      }
      
      .totalSum {
        display: grid;
        grid-template-columns: 1fr auto;
        text-align: left;
        margin-top: 0.5em;
        p {
          margin-top: 0.3em;
          font-size: 12px;
          color: lightgrey;
        }
      }
      
      @media(max-width: 701px) {
      
      .items {
        padding: 1em 0;
      }
      .delivery {
      h5 {
          font-size: 14px;
      }
        img {
          max-width: 150px;
        }
      }
      }
`;
const BackButton = styled.div`
  background-color: orangered;
  padding: 1em 2em;
  color: white;
  border-radius: 6px;
  margin-top: 2em;
  transition: all 100ms ease-in;
  font-weight: 500;
  max-width: 400px;
  cursor: pointer;

  &:hover {
    transform: scale(0.95);
    transition: all 100ms ease-in;
  }
`;

function Voucher() {

    let options = [
        {
            value: 1,
            key: "Paket 1",
            price: 1000,
            bonusPrice: 1000,
            content: [
                {
                    key: '10€ Getränkegutschein',
                    image: 'drinks'
                },
                {
                    key: 'inkl. Bändchen',
                    image: 'blue'
                }
            ]
        },
        {
            value: 2,
            key: "Paket 2",
            price: 2000,
            bonusPrice: 3500,
            content: [
                {
                    key: '30€ Getränkegutschein',
                    key2: '10€ von uns on top',
                    image: 'drinks'
                },
                {
                    key: 'inkl. Bändchen',
                    image: 'blue'
                }
            ]
        },
        {
            value: 3,
            key: "Paket 3",
            price: 3000,
            bonusPrice: 4500,
            content: [
                {
                    key: '45€ Getränkegutschein',
                    key2: '15€ von uns on top',
                    image: 'drinks-2'
                },
                {
                    key: 'inkl. Bändchen',
                    image: 'blue'
                }
            ]
        },
        {
            value: 4,
            key: "Paket - 1/2 Jahr 4 free",
            price: 5000,
            bonusPrice: 5000,
            content: [
                {
                    key: 'inkl. Bändchen',
                    key2: 'Ein halbes Jahr freier Einritt zu ALLEN Veranstaltungen mit diesem Bändchen. STARK LIMITIERT! *Es gelten Sonderregelungen.',
                    image: 'orange'
                }
            ]
        }
    ];

    const [value, setValue ] = useState(options[0]);
    const [count, setCount ] = useState(1);
    const [isLoading, setLoading ] = useState(false);
    const [completePayment, setCompletePayment ] = useState(false);
    const clientId = getPaypalClientId();
    let address;

    let isSelected = (option) => {
        return value.value === option;
    };

    let selectOption = (option) => {
        setValue(option)
    };

    const getImage = image => {
        if (image === 'orange') {
            return orange;
        }

        if (image === 'blue') {
            return blue;
        }

        if (image === 'drinks') {
            return drinks;
        }

        if (image === 'drinks-2') {
            return drinks2;
        }
    };

    let valueOptions = options.map((option, i) => {
       return (
           <div key={i} className={ isSelected(option.value) ? 'singleOption selected' : 'singleOption'}
                onClick={ () => selectOption(option)}
           >
               <h5>{ option.key }</h5>
               <h5>{ parseFloat(option.price / 100).toFixed(2) }€</h5>
           </div>
       )
    });

    let contentOptions = value.content.map((option,i) => (
        <div className={'delivery'}>
            <img alt="optionImage" src={getImage(option.image)}/>
            <h5>
                {option.key}
                <br/>
                <span className={'highlight'}>
                    {option.key2}
                </span>
            </h5>
        </div>
    ));

    let countUp = () => {
        setCount(count + 1);
    };

    let countDown = () => {
        if (count === 1) { return; }
        setCount(count - 1);
    };

    let getTotalSum = () => {
      return value.price * count;
    };

    const onApprove = (data, actions) => {
        actions.order.capture().then(async function(details, cb) {
            try {
                return await onSuccess(details)
            } catch(e) {
                return cb('Error while sending notification');
            }
        });
    };

    const onSuccess = (details) => {
        setLoading(true);
        address = _.get(details, 'purchase_units[0].shipping', {});
        let payer = _.get(details, 'payer', {});
        return createPayment(payer)
    };

    const createPayment = async(payer) => {
        let payment = { value: value, count: count };
        let paymentObject = {
            payment,
            payer,
            address
        };

        await createVoucherPayment(paymentObject);
        setLoading(false);
        setCompletePayment(true);
    };

    return (
        <StyledVoucher>
            <StyledPromo>
                <h5>VIELEN DANK FÜR DEINE UNTERSTÜTZUNG #savetherave</h5>
            </StyledPromo>
            <StyledVoucherContent>
                <StyledPreview>
                    <div className={'value'}>
                        <h5>Bitte Paket wählen:</h5>
                        <div className={'value__options'}>{ valueOptions }</div>
                    </div>
                </StyledPreview>
                { !isLoading && !completePayment &&
                <StyledSelection>
                    <div className={'items'}>
                        <h5>Paketinhalt:</h5>
                        { contentOptions }
                    </div>
                    <div className={'count'}>
                        <h5>Bitte Anzahl wählen:</h5>
                        <div className={'count__counter'}>
                            <FontAwesomeIcon onClick={() => countDown()} className={'icon'} icon={'minus'}/>
                            { count }
                            <FontAwesomeIcon onClick={() => countUp()} className={'icon'} icon={'plus'}/>
                        </div>
                    </div>
                    <div className={'delivery'}>
                        <FontAwesomeIcon className={'icon'} icon="truck-loading"/>
                        <h5>Sofortdruck | Email Versand</h5>
                    </div>
                    <div className={'totalSum'}>
                        <h3>Gesamt</h3>
                        <h3>{ parseFloat(getTotalSum() / 100).toFixed(2) }€</h3>
                        <p>inkl. Mehrwertsteuer & Versand</p>
                    </div>
                    <PayPalButton
                        className={'paypal-buttons'}
                        amount={ getTotalSum()  / 100}
                        onApprove={ onApprove }
                        currency={'EUR'}
                        options={{
                            clientId: clientId,
                            currency: 'EUR'
                        }}
                    />
                </StyledSelection>
                }
                { isLoading && completePayment &&
                <StyledSelection>
                    <div className={'title'}>
                        <h3>Vielen Dank!</h3>
                        <h4>Mit diesem Gutschein unterstützt Du aktiv das Überleben und die Vielfalt des Eichsfelder Nachtlebens.<span aria-label={'emoji'} role={"img"}>🙏</span></h4>
                    </div>
                    <BackButton onClick={() => setCompletePayment(false)}>Zurück</BackButton>
                </StyledSelection>
                }
                { isLoading &&
                <div className="spinner">
                    <FontAwesomeIcon className="icon" icon={['fas', 'circle-notch']} spin/>
                </div>
                }
            </StyledVoucherContent>
        </StyledVoucher>
    )
}

export default Voucher;