import React from "react";
import Header from "../header/header";
import CopyRightBar from "../copyright/copyrightBar";
import Contact from "../content/contact";
function Layout03() {

    return (
        <div>
            <Header/>
            <Contact/>
            <CopyRightBar/>
        </div>
    )
}

export default Layout03;