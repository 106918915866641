import React from 'react';
import styled from "styled-components";
import Start_BG from '../../styles/hp-home-bg.jpg';
import bgPattern from '../../styles/background_pattern_01.png';
import image1 from '../../styles/hp-bg-1.jpg';
import image2 from '../../styles/hp-bg-2.jpg';
import image3 from '../../styles/hp-bg-3.jpg';
import image4 from '../../styles/hp-bg-4.jpg';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';

const StyledHome = styled.div`
    height: 100%;
    width: 100%;
`;

const StyledWelcomeImage = styled.div`
    height: 100%;
    width: 100%;
    background-image: url(${ Start_BG });
    background-position: center;
    background-size: cover;
    min-height: 50vh;
    
    @media(max-width: 501px) {
      min-height: 35vh;
      position: sticky;
      position: -webkit-sticky;
      top: 50px;
      z-index: -1 !important;
    }
`;

const StyledWelcomeGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-column-gap: 2em;
    min-height: 50vh;
    background-color: black;
    background-size: 50px 50px, contain;
    background-image: url(${ bgPattern });
    background-repeat: repeat;
    background-blend-mode: lighten;
    padding: 2em 2em 4em 2em;
    z-index: 2 !important;
    
    a {
      text-decoration: none;
    }
    
    @media(max-width: 701px) {
      padding: 2em 1em 10em 1em;
      grid-gap: 1em;
      min-height: 60vh;
      grid-template-columns: repeat(2,1fr);
    }
    
`;

const styledItem = styled.div`
    background-position: center;
    background-size: cover;
    filter: saturate(0);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 100ms ease-in;

    h2 {
      color: white
    }
    
    .icon {
      color: white;
      margin-bottom: 0.5em;
      font-size: 2em;
    }
    &:hover {
      transform: scale(0.95);
      transition: all 100ms ease-in;
      filter: saturate(1);    
    }
`;

const StyledItem1 = styled(styledItem)`
    background-image: url(${ image1 });
`;

const StyledItem2 = styled(styledItem)`
    background-image: url(${ image2 });
`;

const StyledItem3 = styled(styledItem)`
    background-image: url(${ image3 });
`;

const StyledItem4 = styled(styledItem)`
    background-image: url(${ image4 });
`;


function Home() {

    return (
        <StyledHome>
            <StyledWelcomeImage></StyledWelcomeImage>
            <StyledWelcomeGrid>
                    <StyledItem1>
                        <a href={'https://www.facebook.com/pg/glashaus.worbis/events/?ref=page_internal'} target={'_blank'} rel="noopener noreferrer">
                            <FontAwesomeIcon className={'icon'} icon="video" />
                            <h2>Livestream</h2>
                        </a>
                    </StyledItem1>
                <StyledItem2>
                    <Link to={"/gutscheine"}>
                        <FontAwesomeIcon className={'icon'} icon="gift" />
                        <h2>Gutscheine</h2>
                    </Link>
                </StyledItem2>
                <StyledItem3>
                    <a href={'https://www.instagram.com/glashaus_worbis'} target={'_blank'} rel="noopener noreferrer">
                        <FontAwesomeIcon className={'icon'} icon={['fab', 'instagram']} />
                        <h2>Instagram</h2>
                    </a>
                </StyledItem3>
                <StyledItem4>
                    <a href={'https://www.facebook.com/glashaus.center'} target={'_blank'} rel="noopener noreferrer">
                        <FontAwesomeIcon className={'icon'} icon={['fab', 'facebook']} />
                        <h2>Facebook</h2>
                    </a>
                </StyledItem4>
            </StyledWelcomeGrid>
        </StyledHome>
    )
}

export default Home;