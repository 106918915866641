import React from "react";
import Header from "../header/header";
import CopyRightBar from "../copyright/copyrightBar";
import Voucher from "../content/voucher";

function Layout02() {

    return (
        <div>
            <Header/>
            <Voucher/>
            <CopyRightBar/>
        </div>
    )
}

export default Layout02;