export const getPaypalClientId = () => {

    let clientId = 'AQoO1j7qHMFERZOKB96RJnqXdHmn5a2r2XPBqB2hvcyw3btI2yygsqi-T1MhmcTn8e2_5XVs1C36choG';

    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" ||
        window.location.host === 'reservation.club-toolkit.com' || window.location.hostname === "192.168.178.72") {
        clientId = 'Aas7M8ge86wpjfi2KWJUsZIQtcIHm2E2DQeAZeQPtCxhWqAmRqLhaHqeRoipzRN98u-suUShVjppgP7x'
    }

    return clientId;
};