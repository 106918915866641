import axios from "axios/index";
const API = 'https://qlvhx8p105.execute-api.us-east-2.amazonaws.com/production/events';
const businessIdentifier = 'glashaus';

const createVoucherPayment = async function(paymentObject) {

    let params = {};
    params.method = 'createVoucherPayment';
    params.paymentObject = paymentObject;
    params.businessIdentifier = businessIdentifier;

    try {
        let response =  await axios.post(API, {
            params
        });
        return response.data;

    } catch(err) {
        alert('Fehler aufgetreten');
    }
};

export { createVoucherPayment }