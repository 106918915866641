import React from 'react';
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHeart, faEnvelope, faVideo, faGift, faPlus, faBars, faCircleNotch, faCocktail, faMinus, faTruckLoading} from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import Routes from "./Routes";

library.add(faFacebook, faInstagram, faHeart, faBars, faVideo, faEnvelope, faPlus, faGift, faMinus, faTruckLoading, faCocktail, faCircleNotch);

function App() {
  return (
    <div className="App">
        <Routes/>
    </div>
  );
}

export default App;
